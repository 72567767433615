<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoadingList"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Riskli Sakin"
            icon="mdi mdi-account-alert"
            @clear-filters="$refs.headSearch.reset"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-house-income')"
            :edit-enabled="selectedItems.length === 1"
            @reload="loadList"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:append">
              <AddHouseUsersToBatchForm ref="batchForm" />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    v-if="can('edit-risky-resident') && $refs.batchForm"
                    @click.prevent="handleNotificationClick"
                    :disabled="selectedItems.length === 0"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </template>

                Bildirim Gönder
              </v-tooltip>
            </template>
          </rs-table-title-bar>
          <v-row dense>
            <v-col>
              <v-form
                ref="form"
                @submit.prevent="handleFormSubmit"
                class="mb-3"
              >
                <h6>
                  Lütfen listeyi getirmek için aşağıdaki parametrelerden sadece
                  bir tanesini doldurunuz.
                </h6>
                <v-row cols="12" dense>
                  <v-col sm="3">
                    <rs-text-field
                      type="number"
                      min="1"
                      max="99"
                      step="1"
                      label="Çarpan"
                      v-model="formtime"
                    />
                  </v-col>
                  <v-col sm="3">
                    <rs-money-field
                      v-model="formamount"
                      :rules="
                        !formamount
                          ? []
                          : [
                              rules.min(formamount, 0.01),
                              rules.max(formamount, 9999999.99),
                            ]
                      "
                    />
                  </v-col>
                  <v-col sm="3">
                    <rs-form-buttons
                      :is-loading="isLoading"
                      @submit="handleFormSubmit"
                      hide-cancel
                      hide-save-close
                      saveLabel="Listele"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-5">
                  <v-col cols="12">
                    <rs-select
                      label="Borçlandırma Türleri"
                      v-model="incomeTypeIds"
                      :items="incomeTypeList"
                      :rules="[rules.required]"
                      multiple
                      required
                      hint="Tek tür seçiminde risk tutarı bu türden son borca göre hesaplanır. Çoklu seçimde risk tutarı son aidat borçlandırmasına göre hesaplanır."
                      persistent-hint
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.house_id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item, value }">
          <router-link
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.phone="{ value }">
          <rs-table-cell-phone :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ value }">
          <rs-table-cell-email :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.fee_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.fee_debt="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.risk_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.house_user_balance="{ value }">
          <rs-table-cell-balance :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_amount_remaining="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.address_legal="{ value }">
          <rs-table-cell-string limit="20" :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
    <rs-confirm
      ref="confirmSendSMS"
      :loading="isLoading"
      @confirmed="handleSubmitSendSMSClick"
      @cancelled="$refs.confirmSendSMS.hide()"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import {
  filtersToURL,
  hasExport,
  hasForm,
  hasPermissions,
  isPage,
} from "@/view/mixins";
import AddHouseUsersToBatchForm from "@/view/pages/communication/batch-notifications/AddHouseUsersToBatchForm";

export default {
  mixins: [filtersToURL, hasForm, hasExport, hasPermissions, isPage],
  components: {
    AddHouseUsersToBatchForm,
  },
  computed: {
    ...mapGetters([
      "clusterId",
      "cluster",
      "houseUserTypeList",
      "incomeTypeList",
    ]),
  },
  mounted() {
    this.generateExportColumns(this.headers);
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (
      urlSearchParams.get("formtime") !== null &&
      parseInt(urlSearchParams.get("formtime")) > 0
    ) {
      this.formtime = parseInt(urlSearchParams.get("formtime"));
    } else if (
      urlSearchParams.get("formamount") &&
      parseInt(urlSearchParams.get("formamount")) > 0
    ) {
      this.formamount = parseFloat(urlSearchParams.get("formamount"));
    } else if (this.cluster.person_risk_parameter) {
      this.formtime = this.cluster.person_risk_parameter;
      this.loadList();
    }
  },
  data() {
    return {
      pageMeta: {
        title: "Riskli Sakin Liste",
      },
      titleBarAttrs: {
        exportUrl: "income/residents/risky",
        exportParams: this.getParams,
      },
      formtime: "",
      formamount: "",
      isLoading: false,
      isLoadingList: false,
      incomeTypeIds: [1],
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-house-income"),
        },
        {
          text: "Ad Soyad",
          value: "resident",
          searchable: "text",
          width: "200px",
        },
        {
          text: this.$t("labels.phone"),
          value: "phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.identity_no"),
          value: "identity_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "multiselect",
          options: () => this.cluster.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.door_number_s"),
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          options: () => this.houseUserTypeList,
        },
        {
          text: "Sakin Son Borç",
          value: "fee_amount",
          searchable: "number",
          align: "end",
          hide: () => this.incomeTypeIds.length > 1,
        },
        {
          text: "Çarpan",
          value: "multiplier",
          searchable: false,
          sortable: false,
        },
        {
          text: "Sakin-BB Borç Risk",
          value: "risk_amount",
          align: "end",
          searchable: false,
          width: "120px",
          hide: () => this.incomeTypeIds.length > 1,
        },
        {
          text: "Sakin-BB Toplam Borç",
          value: "fee_debt",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "Kişi-BB Bakiye",
          value: "house_user_balance",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: this.$t("labels.email"),
          value: "email",
          searchable: "text",
        },
        {
          text: "İcra Tutar",
          value: "enforcement_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "İcra Bakiye",
          value: "enforcement_amount_remaining",
          searchable: "number",
          align: "end",
        },
      ],
      options: {
        sortBy: ["resident"],
        sortDesc: [false],
      },
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;
      params.income_type_ids = this.incomeTypeIds;

      if (this.formtime) params.multiplier = this.formtime;

      if (this.formamount) params.risk_amount = this.formamount;

      return params;
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        this.list = [];
        return;
      }

      if (!this.formtime && !this.formamount) {
        this.list = [];
        this.$toast.error("Lütfen parametre doldurunuz");
        return;
      }

      if (this.formtime && this.formamount) {
        this.list = [];
        this.$toast.error("Lütfen sadece 1 parametre doldurunuz");
        return;
      }

      if (this.formtime) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("formtime", this.formtime);
        urlSearchParams.delete("formamount");

        const newUrl =
          window.location.origin +
          window.location.pathname +
          "?" +
          urlSearchParams.toString();

        window.history.replaceState({ path: newUrl }, "", newUrl);
      } else if (this.formamount) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("formamount", this.formamount);
        urlSearchParams.delete("formtime");

        const newUrl =
          window.location.origin +
          window.location.pathname +
          "?" +
          urlSearchParams.toString();

        window.history.replaceState({ path: newUrl }, "", newUrl);
      }

      this.loadList();
    },
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "incomes.houses.show",
        params: {
          id: this.selectedItems[0].house_id,
        },
      });
    },
    loadList() {
      if (
        this.isLoadingList ||
        !this.clusterId ||
        (!this.formtime && !this.formamount)
      ) {
        return;
      }

      this.isLoadingList = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("income/residents/risky", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoadingList = false));
    },
    handleNotificationClick() {
      this.$refs.batchForm.show(
        this.selectedItems.map((item) => item.id),
        10 // template_id for risky resident notification
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId: {
      handler: debounce(function () {
        if (this.cluster.person_risk_parameter) {
          this.formtime = this.cluster.person_risk_parameter;
        } else {
          this.formtime = "";
        }
        this.list = [];
      }),
      deep: true,
    },
  },
};
</script>
