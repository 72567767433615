<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Sakinleri Ekle</template>
    <template slot="body">
      <v-form
        ref="form"
        @submit.prevent="handleFormSubmit"
        v-if="formData.house_user_ids.length > 0"
      >
        <p>
          {{ formData.house_user_ids.length }} adet sakin, aşağıda seçtiğiniz
          toplu gönderime eklenecektir.
        </p>
        <v-row dense>
          <v-col sm="6">
            <rs-select
              label="Bildirim Türü"
              v-model="formData.notification_channel_id"
              :items="notificationChannelListIds"
              :rules="[rules.required]"
              @change="loadBatchList"
            />
            <!-- @change:item="handleChannelUpdate" -->
          </v-col>

          <v-col sm="6">
            <v-switch
              :label="
                formData.send_now ? 'Şimdi Gönder' : 'Toplu Bildirime Ekle'
              "
              v-model="formData.send_now"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="formData.notification_channel_id && !formData.send_now"
        >
          <v-col sm="6">
            <rs-select
              label="Toplu Gönderim Seçin"
              :items="batchList"
              v-model="batchId"
              :rules="[rules.required]"
              required
            />
          </v-col>
        </v-row>

        <v-row
          dense
          v-else-if="formData.notification_channel_id && formData.send_now"
        >
          <v-col sm="6">
            <rs-select-notification-template
              v-model="formData.template_id"
              :channels="formData.notification_channel_id"
              :preselect-system-id="preselectSystemTemplateId"
              sendable
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
          :save-close-label="formData.send_now ? 'Gönder' : undefined"
        />
      </v-form>

      <p v-else>En az bir sakin seçili olmalıdır.</p>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "AddHouseUsersToBatchForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId", "notificationChannelListIds"]),
  },
  data() {
    return {
      formData: {
        house_user_ids: [],
        notification_channel_id: 2,
        send_now: true,
      },
      batchList: [],
      batchId: null,
      preselectSystemTemplateId: null,
      extraParams: {},
    };
  },
  methods: {
    show(houseUserIds, systemTemplateId, extraParams) {
      // get the unique house user ids
      this.formData.house_user_ids = houseUserIds.filter(
        (item, index, array) => {
          return array.indexOf(item, index + 1) === -1;
        }
      );
      this.preselectSystemTemplateId = systemTemplateId || null;
      this.extraParams = extraParams || {};
      this.loadBatchList();
      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    loadBatchList() {
      if (!this.formData.notification_channel_id) {
        return;
      }

      this.batchList = [];
      const params = {
        notification_channel_id: [this.formData.notification_channel_id],
      };
      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      params.for_each_house_user = true;

      this.isLoading = true;

      this.$api
        .query("batch-notifications", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.batchList = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (!this.formData.send_now) {
        return this.handleFormSubmitAdd();
      }

      return this.handleFormSubmitSend();
    },
    handleFormSubmitAdd() {
      const formData = Object.assign({}, this.formData);
      this.isLoading = true;

      this.$api
        .post(`batch-notifications/${this.batchId}/house-users`, formData)
        .then(() => {
          this.$toast.success("Eklendi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmitSend() {
      const formData = Object.assign({}, this.extraParams, this.formData);
      formData.cluster_id = this.clusterId;
      this.isLoading = true;

      this.$api
        .post(`batch-notifications/create-send`, formData)
        .then((response) => {
          this.$toast.success(
            `${response.data.data.sent} adet bildirimin gönderimi başladı`
          );
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
